.update-user-container {
    margin: 100px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    max-width: 800px;
    margin-left: 300px;
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .search-form {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-select,
  .search-input,
  .search-button {
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .search-select {
    width: 120px;
  }
  
  .search-input {
    flex: 1;
  }
  
  .search-button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  .search-results {
    margin-top: 20px;
  }
  
  .results-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .results-table th,
  .results-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .results-table th {
    background-color: #007bff;
    color: white;
  }
  
  .results-table td {
    font-size: 14px;
  }
  
  .update-button {
    padding: 8px 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .update-button:hover {
    background-color: #218838;
  }
  
  /* Modal styles */
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 8px;
    position: relative;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .form-input {
    margin-bottom: 10px;
    padding: 8px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .save-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }
  