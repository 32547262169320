/* ManageCashComp.css */

.manage-cash-container {
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    margin-top: 50px;
  }
  
  .cash-balance-card {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .cash-balance-card h2 {
    margin: 0;
    font-size: 24px;
    color: #343a40;
  }
  
  .cash-balance-card p {
    margin: 10px 0 0 0;
    font-size: 20px;
    color: #28a745;
  }
  
  .cash-balance-card .warning {
    color: #dc3545;
    font-weight: bold;
    margin-top: 10px;
  }
  
  h1 {
    text-align: center;
    color: #343a40;
    margin-bottom: 20px;
  }
  
  .manage-cash-form {
    display: flex;
    flex-direction: column;
  }
  
  .manage-cash-form label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #343a40;
  }
  
  .manage-cash-form input,
  .manage-cash-form select {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
  }
  
  .submit-button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .deposit-history {
    margin-top: 40px;
  }
  
  .deposit-history h2 {
    text-align: center;
    color: #343a40;
    margin-bottom: 20px;
  }
  
  .deposit-history-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .deposit-history-table th,
  .deposit-history-table td {
    border: 1px solid #dee2e6;
    padding: 10px;
    text-align: center;
  }
  
  .deposit-history-table th {
    background-color: #f1f1f1;
  }
  
  .deposit-history-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  