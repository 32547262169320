/* SupportComp.css */

.support-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.chat-container {
  width: 80%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chat {
  height: 400px;
  overflow-y: scroll;
  padding: 10px;
}

.message {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.message.agent {
  justify-content: flex-end;
}

.message-content {
  max-width: 70%;
  padding: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.message.agent .message-content {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.message-input {
  display: flex;
  padding: 10px;
  background-color: #ffffff;
}

.input-field {
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.send-button {
  background-color: #128c7e;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #0a6e5f;
}
