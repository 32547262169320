/* Login.css */

body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    transition: background-image 1s ease-in-out;
  }
  
  .login-form {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 400px;
  }
  
  .logo {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px; /* More rounded textboxes */
    box-sizing: border-box;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    border: none;
    border-radius: 20px; /* More rounded button */
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .loading-gif {
    width: 100px; /* Adjust size as needed */
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
  }
  
  .signing-in-text {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  .logout-spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0; /* Adjust as needed */
  }
  
  .logout-text {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    animation: fadeInOut 1.5s infinite;
  }
  
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }